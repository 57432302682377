<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    width="400px"
    @close="close"
  >
    <div>
      <div class="flex">
        <div>开单类型：</div>
        <div>
          <el-radio-group v-model="billingType">
            <el-radio label="one" class="display-block">自己采购</el-radio>
            <el-radio label="two" class="display-block m-t-10">代他人开单</el-radio>
          </el-radio-group>
          <div class="flex flex-row-center m-t-10">
            <div>选择买家：</div>
            <el-select v-model="buyer" size="mini" placeholder="请选择买家">
              <el-option v-for="item in buyerOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button size="mini" type="primary" plain @click="close">取消</el-button>
      <el-button size="mini" type="primary" @click="confirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "PlaceOrderType",
  data() {
    return {
      dialogVisible: false,
      billingType: "one",
      buyer: "",
      buyerOptions: [{ id: 1, name: "买家1" }],
      info: {}
    };
  },
  methods: {
    show(info) {
      // info.type = productsDetails 商品详情页下单
      // info.type = shoppingCart 购物车页下单
      // info.type = orderList 订单列表再次购买下单
      if (info.type === "productsDetails") {
        info.data = {
          id: info.data.id, // 商品ID
          type: info.data.type, // 商品类型
          qty: info.data.qty, // 数量
          warehouse: info.data.warehouse, // 仓库
          property: info.data.property, // 仓库
          productBatch: info.data.productBatch, // 生产批号
          areaDetailId: 0 // 商城区域详情ID，用来实时关联修理厂用
        };
      }
      if (info.type === "shoppingCart") {
        info.data = info.data.map(item => ({ id: item.id, type: item.type }));
      }
      if (info.type === "orderList") {
        info.data = info.data.details.map(item => ({
          id: item.goodsId, // 商品ID
          type: item.type, // 商品类型
          qty: item.qty, // 数量
          warehouse: item.warehouse, // 仓库
          property: item.property, // 仓库
          productBatch: item.productBatch, // 生产批号
          areaDetailId: 0 // 商城区域详情ID，用来实时关联修理厂用
        }));
      }
      this.info = info;

      // this.dialogVisible = true;
      this.confirm();
    },
    close() {
      this.dialogVisible = false;
    },
    confirm() {
      this.close();
      if (this.$route.path.includes("/order/order-list/order-detail")) {
        this.$router.replace({
          path: "/order/public",
          query: {
            info: encodeURIComponent(JSON.stringify(this.info)),
            url: "/order/order-list/order-detail"
          }
        });
      } else {
        this.$router.push({
          path: "/order/order-list/order-detail",
          query: {
            info: encodeURIComponent(JSON.stringify(this.info))
          }
        });
      }
    }
  }
};
</script>

<style scoped></style>
